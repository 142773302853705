<template>
    <el-form class="evaluationCriteriaForm" ref="EvaluationCriteriaForm" :model="formdata" :rules="formRules" label-width="120px">
      <el-form-item label="评标标准" prop="bidScoringList">
        <el-upload
          v-if="!formDisabled"
          ref="upload"
          name="file"
          :auto-upload="true"
          :action= 'postUrl'
          :show-file-list="false"
          :on-exceed="handleExceed"
          :on-change="handleChansge"
          :on-success="handSuccess"
          :on-error="handError"
          :headers="tokenHeader"
        >
          <el-button size="small" type="primary" plain>上传excel</el-button>
        </el-upload>
        <div class="tableList" :class="{isreadonly:formDisabled}">
          <div class="table">
            <el-table :data="formdata.bidScoringList" style="width: 100%" border>
              <el-table-column :show-overflow-tooltip="true" prop="serialNumber" width="50px" label="序号"></el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="bidScoringMes" min-width="50%" label="评标内容"></el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="score" min-width="10%" label="分值"></el-table-column>
              <el-table-column :show-overflow-tooltip="true" prop="standard" min-width="50%" label="标准"></el-table-column>
            </el-table>
          </div>
        </div>
      </el-form-item>
    </el-form>
</template>

<script>
import { baseURL } from '@/assets/js/http.js'
export default {
  name: 'EvaluationCriteria',
  props: ['formData', 'dataFormat'],
  data () {
    return {
      importDialogVisible: false,
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      postUrl: baseURL + '/api/bid/upload/bidEvaluation',
      formdata: this.formData,
      formRules: this.dataFormat ? this.dataFormat.rules : {},
      formDisabled: false
    }
  },
  mounted: function () {
    if (this.dataFormat) {
      if (this.dataFormat.formDisabled) {
        this.formDisabled = true
      }
    }
  },
  watch: {
    formData: function () {
      this.formdata = this.formData
    }
  },
  methods: {
    // 判断是否只能上传一个文件，超过提示报错
    handleExceed (e) {
      this.$notify.error({
        title: '错误',
        message: '只能上传一个文件'
      })
    },
    // 验证上传文件格式
    handleChansge (file, fileList) {
      if (!/\.(xlsx|xls|XLSX|XLS)$/.test(file.name)) {
        this.$notify.error({
          title: '错误',
          message: '上传文件只能为excel文件，且为xlsx,xls格式'
        })
        return false
      }
    },
    // 上传失败提示
    handError () {
      this.$notify.error({
        title: '错误',
        message: '文件上传失败'
      })
    },
    // 上传成功或错误提示
    handSuccess (response, file, fileList) {
      if (response.code === '200') {
        this.formdata.bidScoringList = response.data
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.evaluationCriteriaForm{
  .tableList{
    padding: 0 0;
  }
  .table{
    margin-top: 20px;
  }
  .isreadonly{
    padding: 0;
    .table{
      margin-top: 0px;
      .el-table{
        margin-top: 10px !important;
      }
    }
  }
}
</style>
